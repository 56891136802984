<template>
    <div v-if="loadingSpinnerFlag" class="bg-[#1D1D1D] fixed w-full h-full">
        <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#F9245A] border-t-transparent absolute top-[50%] left-[50%] ml-[-20px]"></div>
    </div>
    <div v-else class="bg-[#1D1D1D] fixed h-full w-full">
        <div>
            <img src="../assets/login_bg.svg" class="absolute top-[-173px] w-full scale-[2] h-full">
        </div>
        <div class="flex justify-center absolute top-[20px] left-[50%] translate-x-[-50%]">
            <img src="@/mobileApp/assets/sportkit_logo.svg" alt="">
        </div>
        <div class="flex flex-col align-middle items-center justify-center w-full lg:w-[400px] absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%]">
            <div class="flex justify-center">
                <img src="@/mobileApp/assets/logo.svg" class="logo w-[140px]" >
            </div>
            <div class="flex justify-center w-10/12 text-white text-[21px] mb-[18px] mt-[45px]">
                Hello coach!
            </div>
            <div class="bg-[#474849] bg-opacity-20 rounded-[9px] items-center px-[13px] border-[1px] border-[#4EAE3B] flex h-[44px] justify-center w-[90%] mb-[16px]">
                <div class="text-[#4EAE3B] text-[16px] font-semibold relative top-[1px]">+380</div>
                <input v-model="trainerLogin" type="text" @focus="removePlaceholderLogin" id="loginInput" class="bg-transparent text-start placeholder:text-center rounded-[9px] outline-none text-[#4EAE3B] px-3 w-full placeholder-[#8C8C8C] placeholder:text-[13px] placeholder:relative pr-[50px]" placeholder="номер телефону">
            </div>
            <div class="bg-[#474849] bg-opacity-20 rounded-[9px] items-center px-[13px] border-[1px] border-[#4EAE3B] flex h-[44px] justify-center w-[90%] mb-[16px]">
                <input v-model="trainerPassword" type="password" @focus="removePlaceholderPassword" id="passwordInput" class="bg-transparent placeholder:text-center text-start rounded-[9px] outline-none text-[#4EAE3B] px-3 w-full placeholder-[#8C8C8C] placeholder:text-[13px]" placeholder="пароль">
            </div>
            <div class="flex justify-center w-[90%] mt-[10px] mb-[63px]">
                <button @click="sendAuthData" class="bg-[#4EAE3B] border-[1px] border-[#4EAE3B] rounded-[9px] items-center px-[13px] flex h-[44px] justify-center w-full mb-[16px]">
                    <div class="w-full text-[20px] text-white font-extrabold">Log in</div>

                </button>
            </div>
        </div>
        <div class="absolute bottom-[30px] left-[50%] translate-x-[-50%]">
            <!-- <div class="text-[#4EAE3B] text-[10px] text-center lg:mx-0 mb-[35px] mx-10">
                Привіт!<br>Це віртуальний кабінет для тренерів ФК Минай, якщо у тебе виникли проблеми з доступом, звернися до представника клубу за телефоном: +380 ХХ ХХХ ХХ ХХ
            </div> -->
            <div class="text-[#fff] text-[11px] text-center w-full">
                © 2023 ALL RIGHTS RESERVED
            </div>
        </div>
    </div>
</template>

<script>
    import { onMounted, ref } from 'vue'
    import { useRouter, useRoute } from 'vue-router'
    import { getTokens } from '../services/apiRequests'
    import { useMainStore } from '../stores/mainStore'

    export default {
        name: 'Login',
        setup() {
            const router = useRouter()
            const route = useRoute()
            const store = useMainStore()
            const loadingSpinnerFlag = ref(true)

            const trainerLogin = ref('')
            const trainerPassword = ref('')

            const screenWidth = ref(window.screen.width)

            const sendAuthData = async () => {
                loadingSpinnerFlag.value = true
                const res = await getTokens('380' + trainerLogin.value, trainerPassword.value)
                console.log('tokens', res)
                if (res) {
                    await store.getTrainerData()
                    await store.getStudentBalanceData()
                    await store.getServiceBalanceData()
                    await store.getTrainerGroups()
                    await store.getAllTrainerPlanedTrainings()
                    await store.getTrainerRealIncomes()
                    router.push('/')
                } else {
                    alert('Невірний логін або пароль')
                }
                loadingSpinnerFlag.value = false
            }

            onMounted(() => {
                loadingSpinnerFlag.value = false

                window.addEventListener('resize', () => {
                    screenWidth.value = window.screen.width
                })
            })

            return {
                router,
                route,
                trainerLogin,
                trainerPassword,
                sendAuthData,
                loadingSpinnerFlag,
                screenWidth
            }
        }
    }
</script>

<style lang="scss" scoped>
    .logo {
        filter: drop-shadow(0px 0px 3px #ffffff);
    }
</style>